import React from 'react'

import Congratulation from '../../components/Congratulation/congratulation'

const TrainingCompletion = () => {
  const text = (
    <div style={{ textAlign: 'left' }}>
      <strong style={{ textAlign: 'center', display: 'block' }}>
        You&apos;re now a Certified Dreamer!
      </strong>
      <div style={{ padding: '10px 0' }}>
       Now that you’ve loaded your cargo and launched your ships, you are ready for the next leg of your journey: bringing your ships safely to shore. <br />  <br />

The navigation bar has now been unlocked so you can view and edit all your existing ships, as well as add new ones.  <br />  <br />

When you click the Finish button below, the navigation button will be unlocked, so you can view and edit your existing ships, as well as add new ones.  <br />  <br />

Go to the 7C&apos;s Training button to learn about the final C&apos;s that will teach you how to bring the ships you created into shore: Calibrate, Crew, Clues, and Claim.

      </div>
      <strong style={{ textAlign: 'center', display: 'block' }}>
      May Your Ships Come In!
      </strong>
    </div>
  )

  const path = 'ship-sea'
  const title = 'Success'
  const subtitle = 'Congratulations!'

  return (
    <Congratulation buttonText={'Finish'} path={path} text={text} title={title} subtitle={subtitle} />
  )
}

export default TrainingCompletion
