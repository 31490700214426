import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { toPng } from 'html-to-image'
import React, { useRef, useCallback, useState, useEffect, RefObject } from 'react'
import { useLocation } from 'react-router-dom'

import audioPreview from '../../../assets/images/icons/audio_preview.png'
import { AuthContext } from '../../../context/AuthContext'
import { ShipManifestContext } from '../../../context/ShipManifestContext'
import { audioMerge } from '../../../services/audio.services/audio'
import { pdfData } from '../../../services/ship.service/pdfData'
import { ShipItemsPdfData } from '../../../types'
import ShipItemPng from '../../Downloads/PNG/shipItemPng'
import PdfDoc from '../../Pdf/shipItemsPdf'
// import ShipManifests from '../../ShipManifests'

const DreamDesignDownload: React.FC<{ audioRequired?: boolean, showAudio?: boolean }> = ({ audioRequired, showAudio }) => {
  const [loading, setLoading] = useState(false)
  const [shipData, setShipData] = React.useState<ShipItemsPdfData[]>([])
  const [overviewPngDownload, setOverviewPngDownload] = useState(false)
  const [boardPngDownload, setBoardPngDownload] = useState(false)
  const { user } = React.useContext(AuthContext)
  const ref = useRef<HTMLDivElement>(null)
  const secondRef = useRef<HTMLDivElement>(null)
  const [soundtrackSelectValue, setSoundtrackSelectValue] = useState<string>('')
  const { audioList } = React.useContext(ShipManifestContext)
  const location = useLocation()

  const downloadHandle = async () => {
    let data = null
    if (location.pathname !== 'training') {
      if (soundtrackSelectValue === 'no-background') {
        data = { audioId: '' }
      } else {
        data = { audioId: soundtrackSelectValue }
      }
    } else {
      const audio = user.videoBookmark?.training_data?.audioId
      data = {
        audioId: audio?.length ? audio : null
      }
    }
    setLoading(true)
    try {
      const resp = await audioMerge(data)
      setLoading(false)
      const audioFilePath = resp.data.data.url
      const link = document.createElement('a')
      link.href = audioFilePath
      link.download = 'my_audio.mp3'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error: any) {
      setLoading(false)
    }
  }

  useEffect(() => {
    pdfData().then((res) => {
      setShipData(res.data.data)
    })
  }, [])
  const onButtonClick = useCallback((manifestRequired: Boolean, ref: RefObject<HTMLDivElement>) => {
    if (ref.current === null) {
      return
    }
    const element = ref.current
    element.style.minWidth = '595px'
    // top border
    const topBorder = element.querySelector('#top-border') as HTMLElement
    topBorder.style.height = '5px'
    // content-header
    const contentHeader = element.querySelector('#content-header') as HTMLElement
    contentHeader.style.padding = '20px'
    // logo
    const logo = element.querySelector('#header-logo') as HTMLElement
    logo.style.maxWidth = '130px'
    // header text
    const headerText = element.querySelector('#header-text') as HTMLElement
    headerText.style.marginRight = '10px'
    const headerSubject = element.querySelector('#header-subject') as HTMLElement
    headerSubject.style.fontSize = '15px'
    const headerUserTitle = element.querySelector('#header-user-title') as HTMLElement
    headerUserTitle.style.fontSize = '10px'
    const headerUserImage = element.querySelector('#header-user-image') as HTMLElement
    if (headerUserImage) {
      headerUserImage.style.width = '35px'
      headerUserImage.style.height = '35px'
    }
    const titleWrapper = element.getElementsByClassName('title-wrapper')
    Array.from(titleWrapper).forEach((ele) => {
      const wrapper = ele as HTMLElement
      wrapper.style.padding = '5px 0px 10px 25px'
      wrapper.style.fontSize = '12px'
      wrapper.style.width = '60px'
      wrapper.style.borderTopRightRadius = '10px'
    })

    const shipContentWrapper = element.getElementsByClassName('ship-content-wrapper')
    Array.from(shipContentWrapper).forEach((ele) => {
      const wrapper = ele as HTMLElement
      wrapper.style.padding = '10px 20px 20px 20px'
    })

    const imageWrapperPdf = element.getElementsByClassName('image-wrapper-pdf')
    Array.from(imageWrapperPdf).forEach((ele) => {
      const wrapper = ele as HTMLElement
      wrapper.style.height = manifestRequired ? '50px' : '80px'
      wrapper.style.width = '100px'
      wrapper.style.borderRadius = '5px'
    })
    const manifest = element.getElementsByClassName('ship-manifest')
    Array.from(manifest).forEach((ele) => {
      const wrapper = ele as HTMLElement
      wrapper.style.fontSize = '8px'
      wrapper.style.maxWidth = '100px'
    })
    const shipTitle = element.getElementsByClassName('ship-item-title')
    Array.from(shipTitle).forEach((ele) => {
      const wrapper = ele as HTMLElement
      wrapper.style.fontSize = '9px'
    })

    const shipItemTitle = element.getElementsByClassName('ship-item-title-wrapper')
    Array.from(shipItemTitle).forEach((ele) => {
      const wrapper = ele as HTMLElement
      wrapper.style.left = '5px'
    })
    if (ref.current.dataset.name === 'with-manifest') {
      setOverviewPngDownload(true)
    } else {
      setBoardPngDownload(true)
    }
    toPng(element, { cacheBust: true })
      .then((dataUrl: string) => {
        const link = document.createElement('a')
        link.download = `${user.first_name}-${ref.current?.dataset?.name === 'with-manifest' ? 'dream-overview' : 'dream-board'}.png`
        link.href = dataUrl
        link.click()
        if (ref.current?.dataset?.name === 'with-manifest') {
          setOverviewPngDownload(false)
        } else {
          setBoardPngDownload(false)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
    setTimeout(() => {
      element.style.minWidth = '216px'
      topBorder.style.height = '2px'
      contentHeader.style.padding = '5px'
      logo.style.maxWidth = '39px'
      headerText.style.marginRight = '5px'
      headerSubject.style.fontSize = '3px'
      headerUserTitle.style.fontSize = '2.6px'
      if (headerUserImage) {
        headerUserImage.style.width = '9.89px'
        headerUserImage.style.height = '9.89px'
      }
      Array.from(titleWrapper).forEach((ele) => {
        const wrapper = ele as HTMLElement
        wrapper.style.padding = '2px 0 2px 13px'
        wrapper.style.fontSize = '3.12px'
        wrapper.style.width = '15px'
        wrapper.style.borderTopRightRadius = '2px'
        wrapper.style.fontSize = '3.12px'
      })

      Array.from(shipTitle).forEach((ele) => {
        const wrapper = ele as HTMLElement
        wrapper.style.fontSize = '3px'
      })

      Array.from(shipContentWrapper).forEach((ele) => {
        const wrapper = ele as HTMLElement
        wrapper.style.padding = '4px 4px 4px 13px'
      })
      Array.from(imageWrapperPdf).forEach((ele) => {
        const wrapper = ele as HTMLElement
        wrapper.style.height = ref.current?.dataset?.name === 'with-manifest' ? '10px' : '20px'
        wrapper.style.width = '32px'
        wrapper.style.borderRadius = '2px'
      })
      Array.from(shipItemTitle).forEach((ele) => {
        const wrapper = ele as HTMLElement
        wrapper.style.left = '2px'
      })

      Array.from(manifest).forEach((ele) => {
        const wrapper = ele as HTMLElement
        wrapper.style.fontSize = '3px'
        wrapper.style.maxWidth = '30px'
      })
    })
  }, [ref])

  const handleSelectAudio = (e: any) => {
    console.log(e.target.value)
    setSoundtrackSelectValue(e.target.value)
  }

  return (
    <>
      <div className="body-content">
        <div style={{ display: showAudio ? 'none' : '' }}>
          <div className='body-content__title'>DREAM DESIGN OVERVIEW</div>
          <div className='download-mob-body' style={{ display: 'flex', margin: '5px 0 0 20px', width: '95%' }}>
            <div style={{ width: '110%', height: '250px', overflowY: 'auto' }}>
              <div className="body-content__png" ref={ref} data-name="with-manifest">
                <ShipItemPng shipData={shipData} user={user} manifestRequired={true} />
              </div>
            </div>
            <div className="body-content__button" style={{ width: 'auto' }}>
              <PDFDownloadLink document={<PdfDoc shipData={shipData} user={user} overviewRequired={true} boardRequired={false} />} fileName={user && `dream-design-${user.first_name}.pdf`} style={{ textDecoration: 'none' }}>
                <Button className='popup-body__download-btn' style={{
                  background: 'black',
                  color: '#FFF'
                }}>Download PDF</Button>
              </PDFDownloadLink>
              <Button className='popup-body__download-btn' style={{
                border: '1px solid #282F3E',
                color: '#282F3E'
              }} onClick={() => onButtonClick(true, ref)}>{
                  overviewPngDownload
                    ? <CircularProgress size='15px' />
                    : 'Download PNG'
                }</Button>
            </div>
          </div>
          <div className='body-content__title' style={{ marginTop: '30px' }}>Vision Board</div>
          <div className='download-mob-body' style={{ display: 'flex', margin: '5px 0 0 20px', width: '95%' }}>
            <div className="body-content__png" ref={secondRef} data-name="without-manifest">
              <ShipItemPng shipData={shipData} user={user} manifestRequired={false} />
            </div>
            <div className="body-content__button">
              <PDFDownloadLink document={<PdfDoc shipData={shipData} user={user} overviewRequired={false} boardRequired={true} />} fileName={user && `dream-design-${user.first_name}.pdf`} style={{ textDecoration: 'none' }}>
                <Button className='popup-body__download-btn' style={{
                  background: 'black',
                  color: '#FFF'
                }}>Download PDF</Button>
              </PDFDownloadLink>

              <Button className='popup-body__download-btn' style={{
                border: '1px solid #282F3E',
                color: '#282F3E'
              }} onClick={() => onButtonClick(false, secondRef)}>
                {
                  boardPngDownload
                    ? <CircularProgress size='15px' />
                    : 'Download PNG'
                }
              </Button>
            </div>
          </div>
        </div>
        <div style={{ display: showAudio ? '' : 'none' }}>

          <div className='body-content__title' style={{ marginTop: '30px' }}>Manifest Readings</div>
          <div className='download-mob-body' style={{ display: 'flex', alignItems: 'center' }}>

            <div style={{ width: '90%' }}>
              <img src={audioPreview} alt="" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              {
                audioRequired
                  ? <>
                    <FormControl fullWidth style={{ marginTop: '10px' }}>
                      <InputLabel id="demo-simple-select-label" style={{ paddingBottom: '10px', paddingLeft: '10px' }}>Sound Track</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        sx={{
                          height: '45px',
                          width: '220px',
                          marginLeft: '15px'
                        }}
                        onChange={handleSelectAudio}
                      >
                        {
                          audioList.map((track) => {
                            return <MenuItem value={track.id} key={track.id}>{track.name}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                  </>
                  : null
              }
              <Button className='popup-body__download-btn' style={{
                border: '1px solid #282F3E',
                color: '#282F3E'
              }} onClick={downloadHandle}>
                {
                  loading ? <CircularProgress size='15px' /> : 'DOWNLOAD MP3'
                }
              </Button>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default DreamDesignDownload
