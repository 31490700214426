import { Button } from '@mui/material'
import React from 'react'

import Text from '../../components/Text/Text'

interface Iprops {
  title: string
  subtitle: string
  text: any
  path: string,
  buttonText: string,
  showButton?: boolean
}

const GetStarted: React.FC<Iprops> = ({ title, subtitle, text, path, buttonText, showButton = true }) => {
  const handleClick = () => {
    window.location.href = path
  }
  const divStyle: React.CSSProperties = !showButton
    ? {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        top: '20%',
        bottom: '20%',
        backgroundColor: 'white',
        transform: 'translate(-50%, -20%)',
        left: '50%',
        borderRadius: '10px',
        paddingTop: '35px',
        textAlign: 'left',
        height: '100%'
      }
    : {}
  return (
    <div style={divStyle}>
      <Text preset='eyebrow' text={title} style={{ textAlign: 'center', marginBottom: 0 }} />
      <Text preset="h1" text={subtitle} style={{ textAlign: 'center', marginTop: '0' }} />
      <Text preset='paragraph' text={text} style={{ textAlign: 'center', marginBottom: 0, maxWidth: '550px', margin: '0 auto' }} />
      <div style={{ textAlign: 'center' }}>
        {showButton && <Button
          size="large"
          type="submit"
          className="form-submit-button"
          onClick={handleClick}> {buttonText}
        </Button>}
      </div>
    </div>
  )
}

export default GetStarted
